import { createSlice } from '@reduxjs/toolkit';
import store from '../../state/store';

const initialState = {
    themeMode: 0,
    isScrolled: false
};

// Slice
const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setThemeMode: (state, action) => {
            const { payload } = action;
            state.themeMode = payload;
        },
        setIsScrolled: (state, action) => {
          const { payload } = action;
          state.isScrolled = payload;
        }
    }
});

// Reducers
export default uiSlice.reducer;

// Selectors
export const uiSelector = (state) => state.ui;

// Actions
const { setThemeMode, setIsScrolled } = uiSlice.actions;

// Thunks
export const toggleThemeMode = () => (dispatch) => {
    const { themeMode } = uiSelector(store.getState());
    const mode = themeMode === 0 ? 1 : 0;

    dispatch(setThemeMode(mode));
};

export const handleScroll = () => (dispatch) => {
    const isScrolled = document.body.scrollTop > 25 || document.documentElement.scrollTop > 25;
    dispatch(setIsScrolled(isScrolled));
  };