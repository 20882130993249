// const isProd = process.env.NODE_ENV === 'production';
// const assetPrefix = process.env.ASSET_PREFIX || '';
// const configPrefix = process.env.REACT_APP_ENV || process.env.NODE_ENV;

// const Config = require(`./config.${configPrefix}.json`);

// Config.assetPrefix = (isProd ? assetPrefix : '');

// export default Config;

const isProd = process.env.NODE_ENV === 'production';
const assetPrefix = process.env.ASSET_PREFIX || '';
const configPrefix = process.env.REACT_APP_ENV || process.env.NODE_ENV;

const Config = require(`./config.${configPrefix}.json`);

Config.assetPrefix = (isProd ? assetPrefix : '');

module.exports = Config; // Use module.exports instead of export default
