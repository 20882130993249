import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user: null,
    isLoggedIn: false,
    isTrialChecked: false
};

// Slice
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state, action) => {
            const { payload } = action;
            state.user = payload;
        },

        setIsLoggedIn: (state, action) => {
            const { payload } = action;
            state.isLoggedIn = payload;
        },

        setIsTrialChecked: (state, action) => {
            const { payload } = action;
            state.isTrialChecked = payload;
        }
    }
});

// Reducers
export default authSlice.reducer;

// Selectors
export const getUser = (state) => state.auth.user;
export const getIsLoggedIn = (state) => state.auth.isLoggedIn;
export const getIsTrialChecked = (state) => state.auth.isTrialChecked;

// Actions
export const { setUser, setIsLoggedIn, setIsTrialChecked } = authSlice.actions;
