exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-embed-js": () => import("./../../../src/pages/embed.js" /* webpackChunkName: "component---src-pages-embed-js" */),
  "component---src-pages-pass-reset-js": () => import("./../../../src/pages/pass-reset.js" /* webpackChunkName: "component---src-pages-pass-reset-js" */),
  "component---src-pages-profile-authentication-js": () => import("./../../../src/pages/profile-authentication.js" /* webpackChunkName: "component---src-pages-profile-authentication-js" */),
  "component---src-pages-register-jsx": () => import("./../../../src/pages/register.jsx" /* webpackChunkName: "component---src-pages-register-jsx" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

