import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userReducer from './users/userReducer'
import uiReducer from './ui/uiReducer'


import Config from "../config/config";
export const getUserEndpoint = `${Config.apiURL}/api/users/me?populate=deep`;

const rootReducer = combineReducers({
    auth: userReducer,
    ui: uiReducer
});

const store = configureStore({
    reducer: rootReducer
});

export default store;
